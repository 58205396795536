import { ICar } from '@/interfaces/car/ICar';
import { OrderBy } from '@/enums/OrderBy';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { sortCarList } from '@/util/misc';
import { IFilterParameter } from '@/interfaces/IFilterParameter';
import { AvailabilitySteps, get_availability_days } from '@/enums/AvailabilitySteps';
import { Drive } from '@/enums/Drive';
import { Brand } from '@/enums/Brand';
import { VehicleClass } from '@/enums/VehicleClass';
import { VehicleType } from '@/enums/VehicleType';
import { IMake } from '@/interfaces/car/IMake';
import { HitchType } from '@/enums/Hitch';
import { VehicleState } from '@/enums/VehicleState';

export const useSelectorStore = defineStore('selector', () => {
    const carsResult = ref<ICar[] | undefined>(undefined);
    const allCars = ref<ICar[] | undefined>(undefined);
    const filterParameter = ref<IFilterParameter>({
        carIds: undefined,
        brands: [],
        vehicleClasses: [],
        vehicleTypes: [],
        seats: [],
        rangeItem: undefined,
        priceItem: undefined,
        chargeTimeDcItem: undefined,
        chargeRateDcMaxItem: undefined,
        accelerationItem: undefined,
        hitch: undefined,
        drives: [],
        misc: {
            bestDeal: false,
            immediatelyAvail: false,
            used: false,
            new: false,
            usedYoung: false,
            trialSubscription: false,
        },
        availability: AvailabilitySteps.ALL,
    });
    const orderBy = ref<OrderBy>(OrderBy.POPULARITY);
    const displayFilter = ref<boolean>(false);

    function sortCars() {
        if (carsResult.value) {
            sortCarList(orderBy.value, carsResult.value);
        }

        if (allCars.value) {
            sortCarList(orderBy.value, allCars.value);
        }
    }

    function countCars(): number {
        if (!carsResult.value) {
            return 0;
        }

        return carsResult.value.length;
    }

    const lowestCarPriceFilter = computed(() => {
        if (!allCars.value) {
            return 0;
        }

        // get the lowest price of all cars
        const lowestCarPrice = allCars.value.reduce((prev, current) => {
            if (current.stickerpricedata && current.stickerpricedata.price) {
                return prev < current.stickerpricedata.price
                    ? prev
                    : current.stickerpricedata.price;
            } else {
                return prev < current.dynamic_from_price ? prev : current.dynamic_from_price;
            }
        }, Number.MAX_VALUE);

        // round to the nearest below 100
        return Math.floor(lowestCarPrice / 100) * 100;
    });

    const highestCarPriceFilter = computed(() => {
        if (!allCars.value) {
            return 0;
        }

        const highestCarPrice = allCars.value.reduce((prev, current) => {
            if (current.stickerpricedata && current.stickerpricedata.price) {
                return prev > current.stickerpricedata.price
                    ? prev
                    : current.stickerpricedata.price;
            } else {
                return prev > current.dynamic_from_price ? prev : current.dynamic_from_price;
            }
        }, 0);

        return Math.ceil(highestCarPrice / 100) * 100;
    });

    function appendFilterDrive(drive: Drive) {
        filterParameter.value.drives.push(drive);
    }

    function removeFilterDrive(drive: Drive) {
        filterParameter.value.drives = filterParameter.value.drives.filter(
            (item) => item !== drive,
        );
    }

    function appendFilterBrand(brand: Brand) {
        filterParameter.value.brands.push(brand);
    }

    function removeFilterBrand(brand: Brand) {
        filterParameter.value.brands = filterParameter.value.brands.filter(
            (item) => item !== brand,
        );
    }

    function appendFilterVehicleClass(vehicleClass: VehicleClass) {
        filterParameter.value.vehicleClasses?.push(vehicleClass);
    }

    function removeFilterVehicleClass(vehicleClass: VehicleClass) {
        filterParameter.value.vehicleClasses = filterParameter.value.vehicleClasses?.filter(
            (item) => item !== vehicleClass,
        );
    }

    function appendFilterVehicleType(vehicleType: VehicleType) {
        filterParameter.value.vehicleTypes?.push(vehicleType);
    }

    function removeFilterVehicleType(vehicleType: VehicleType) {
        filterParameter.value.vehicleTypes = filterParameter.value.vehicleTypes?.filter(
            (item) => item !== vehicleType,
        );
    }

    function appendFilterSeat(seat: number) {
        filterParameter.value.seats.push(seat);
    }

    function removeFilterSeat(seat: number) {
        filterParameter.value.seats = filterParameter.value.seats.filter((item) => item !== seat);
    }

    function clearAllFilter() {
        // call all clear functions
        Object.values(clear).forEach((f) => f());
    }

    // group the clearing function in clear object
    const clear = {
        filterSeats: () => {
            filterParameter.value.seats.splice(0);
        },
        filterAvailability: () => {
            filterParameter.value.availability = AvailabilitySteps.ALL;
        },
        filterRange: () => {
            filterParameter.value.rangeItem = undefined;
        },
        filterPrice: () => {
            filterParameter.value.priceItem = undefined;
        },
        filterChargeTime: () => {
            filterParameter.value.chargeTimeDcItem = undefined;
        },
        filterChargeRate: () => {
            filterParameter.value.chargeRateDcMaxItem = undefined;
        },
        filterAcceleration: () => {
            filterParameter.value.accelerationItem = undefined;
        },
        filterHitch: () => {
            filterParameter.value.hitch = undefined;
        },
        filterDrive: () => {
            filterParameter.value.drives?.splice(0);
        },
        filterBrands: () => {
            filterParameter.value.brands.splice(0);
        },
        filterVehicleClass: () => {
            filterParameter.value.vehicleClasses?.splice(0);
        },
        filterVehicleType: () => {
            filterParameter.value.vehicleTypes?.splice(0);
        },
        filterVehicleCondition: () => {
            filterParameter.value.misc.new = false;
            filterParameter.value.misc.used = false;
            filterParameter.value.misc.usedYoung = false;
        },
        filterBestDeal: () => {
            filterParameter.value.misc.bestDeal = false;
        },
        filterTrialSubscription: () => {
            filterParameter.value.misc.trialSubscription = false;
        },
    };

    function hideFilter() {
        displayFilter.value = false;
    }

    function showFilter() {
        displayFilter.value = true;
    }

    /**
     * Apply the filter so car list (by FilterParameter object).
     * Filter 'magic' is here implemented.
     */
    function applyFilter() {
        if (!allCars.value || !carsResult.value) {
            throw new Error('allCars or carsResult is undefined');
        }

        if (filterParameter.value.brands.length) {
            carsResult.value = allCars.value.filter((car) =>
                filterParameter.value.brands.includes((car.model_variant.model.make as IMake).id),
            );
        } else {
            // no brand selected, start with all cars (copy them with spread operator)
            carsResult.value = [...allCars.value];
        }

        if (filterParameter.value.carIds) {
            carsResult.value = carsResult.value.filter((car) =>
                filterParameter.value.carIds?.includes(car.id),
            );
        }

        if (filterParameter.value.availability !== AvailabilitySteps.ALL) {
            if (filterParameter.value.availability === AvailabilitySteps.IMMEDIATELY) {
                // display cars which are either immediately or 'quickly' available (ia or qa property)
                //carsResult.value = carsResult.value.filter((car) => car.is_ia || car.is_qa);
                carsResult.value = carsResult.value.filter((car) => car.is_ia);
            } else if (
                filterParameter.value.availability === AvailabilitySteps.M3 ||
                filterParameter.value.availability === AvailabilitySteps.M6 ||
                filterParameter.value.availability === AvailabilitySteps.M12
            ) {
                const days = get_availability_days(filterParameter.value.availability);

                carsResult.value = carsResult.value.filter(
                    (car) =>
                        car.min_delivery_time_combination.days !== undefined &&
                        car.min_delivery_time_combination.days !== null &&
                        car.min_delivery_time_combination.days <= days,
                );
            }
        }

        if (filterParameter.value.misc.bestDeal) {
            carsResult.value = carsResult.value.filter((car) => car.is_promo);
        }

        // complicated
        if (
            !(
                filterParameter.value.misc.new &&
                filterParameter.value.misc.used &&
                filterParameter.value.misc.usedYoung
            )
        ) {
            if (filterParameter.value.misc.used && filterParameter.value.misc.usedYoung) {
                carsResult.value = carsResult.value.filter(
                    (car) =>
                        car.vehicle_state === VehicleState.USED_CAR ||
                        car.vehicle_state === VehicleState.JUNGWAGEN,
                );
            } else if (filterParameter.value.misc.used && filterParameter.value.misc.new) {
                carsResult.value = carsResult.value.filter(
                    (car) => car.vehicle_state !== VehicleState.JUNGWAGEN,
                );
            } else if (filterParameter.value.misc.usedYoung && filterParameter.value.misc.new) {
                carsResult.value = carsResult.value.filter(
                    (car) => car.vehicle_state !== VehicleState.USED_CAR,
                );
            } else {
                if (filterParameter.value.misc.used) {
                    carsResult.value = carsResult.value.filter(
                        (car) => car.vehicle_state === VehicleState.USED_CAR,
                    );
                }

                if (filterParameter.value.misc.usedYoung) {
                    carsResult.value = carsResult.value.filter(
                        (car) => car.vehicle_state === VehicleState.JUNGWAGEN,
                    );
                }

                if (filterParameter.value.misc.new) {
                    carsResult.value = carsResult.value.filter(
                        (car) =>
                            car.vehicle_state !== VehicleState.USED_CAR &&
                            car.vehicle_state !== VehicleState.JUNGWAGEN,
                    );
                }
            }
        }

        if (filterParameter.value.misc.trialSubscription) {
            carsResult.value = carsResult.value.filter((car) => car.offer_trialsubscription);
        }

        if (filterParameter.value.rangeItem) {
            carsResult.value = carsResult.value.filter(
                (car) =>
                    // @ts-ignore
                    // @TODO: possibly false positive tslint ('object can be undefined') here?
                    car.technicaldata.range >= filterParameter.value.rangeItem.from,
            );
        }

        if (filterParameter.value.priceItem) {
            carsResult.value = carsResult.value.filter((car) => {
                if (filterParameter.value.priceItem) {
                    if (car.stickerpricedata && car.stickerpricedata.price) {
                        return (
                            car.stickerpricedata.price >= filterParameter.value.priceItem.from &&
                            car.stickerpricedata.price <= filterParameter.value.priceItem.to
                        );
                    } else {
                        return (
                            car.dynamic_from_price >= filterParameter.value.priceItem.from &&
                            car.dynamic_from_price <= filterParameter.value.priceItem.to
                        );
                    }
                }
            });
        }

        if (filterParameter.value.chargeTimeDcItem) {
            carsResult.value = carsResult.value.filter(
                (car) =>
                    // @ts-ignore
                    // @TODO: possibly false positive tslint ('object can be undefined') here?
                    car.technicaldata.charge_time_dc <= filterParameter.value.chargeTimeDcItem.to,
            );
        }

        if (filterParameter.value.chargeRateDcMaxItem) {
            carsResult.value = carsResult.value.filter(
                (car) =>
                    car.technicaldata.charging_rate_dc_max >=
                    // @ts-ignore
                    // @TODO: possibly false positive tslint ('object can be undefined') here?
                    filterParameter.value.chargeRateDcMaxItem.from,
            );
        }

        if (filterParameter.value.accelerationItem) {
            carsResult.value = carsResult.value.filter(
                (car) =>
                    // @ts-ignore
                    // @TODO: possibly false positive tslint ('object can be undefined') here?
                    car.technicaldata.acceleration <= filterParameter.value.accelerationItem.to,
            );
        }

        if (filterParameter.value.hitch) {
            if (filterParameter.value.hitch == HitchType.BIKE_RACK) {
                carsResult.value = carsResult.value.filter(
                    (car) =>
                        car.technicaldata.hitch_type == HitchType.BIKE_RACK ||
                        car.technicaldata.hitch_type == HitchType.TRAILER,
                );
            } else {
                carsResult.value = carsResult.value.filter(
                    (car) => car.technicaldata.hitch_type == filterParameter.value.hitch,
                );
            }
        }

        if (filterParameter.value.drives.length) {
            carsResult.value = carsResult.value.filter((car) =>
                filterParameter.value.drives.includes(car.technicaldata.drive),
            );
        }

        if (filterParameter.value.vehicleClasses.length) {
            carsResult.value = carsResult.value.filter((car) =>
                filterParameter.value.vehicleClasses.includes(car.model_variant.model.category.id),
            );
        }

        if (filterParameter.value.vehicleTypes.length) {
            carsResult.value = carsResult.value.filter((car) =>
                filterParameter.value.vehicleTypes.includes(car.model_variant.model.type.id),
            );
        }

        if (filterParameter.value.seats.length) {
            carsResult.value = carsResult.value.filter(
                (car) =>
                    filterParameter.value.seats.includes(car.technicaldata.seats_standard) ||
                    filterParameter.value.seats.includes(car.technicaldata.seats_optional),
            );
        }
    }

    return {
        carsResult,
        filterParameter,
        allCars,
        orderBy,
        displayFilter,
        applyFilter,
        clear,
        clearAllFilter,
        sortCars,
        countCars,
        appendFilterVehicleClass,
        removeFilterVehicleClass,
        appendFilterVehicleType,
        removeFilterVehicleType,
        appendFilterSeat,
        removeFilterSeat,
        appendFilterDrive,
        removeFilterDrive,
        appendFilterBrand,
        removeFilterBrand,
        showFilter,
        hideFilter,
        highestCarPriceFilter,
        lowestCarPriceFilter,
    };
});
