import VueRouter, { Route } from 'vue-router';
import { Country } from '@/enums/Country';
import { SelectorQueryParam } from '@/enums/SelectorQueryParam';
import { Cooperation } from '@/enums/Cooperation';
import { Brand } from '@/enums/Brand';
import { VehicleClass } from '@/enums/VehicleClass';
import { VehicleType } from '@/enums/VehicleType';
import { Drive } from '@/enums/Drive';
import { IRangeItem } from '@/interfaces/IRangeItem';

/**
 * Remove given query parameter from route.
 *
 * @param param selector query parameters
 * @param route current VueRoute
 * @param router current VueRouter
 */
export function removeQueryParam(param: SelectorQueryParam, route: Route, router: VueRouter): void {
    if (Object.prototype.hasOwnProperty.call(route.query, param)) {
        const query = Object.assign({}, route.query);

        delete query[param];
        router.replace({ query });
    }
}

/**
 * Get the current cooperation param.
 *
 * @param route the current route
 * @return the current operation or undefined
 */
export function getCO(route: Route): Cooperation | undefined {
    if (
        Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.COOPS) &&
        route.query.co === Cooperation.SCHARINGER
    ) {
        return Cooperation.SCHARINGER;
    }

    return undefined;
}

/**
 * Check if the quiz result mode is set.
 *
 * @param route
 */
export function isQuizResult(route: Route): boolean {
    if (SelectorQueryParam.QUIZ_RESULT in route.query) {
        return route.query[SelectorQueryParam.QUIZ_RESULT] === '1';
    }

    return false;
}

/**
 * Check if the B2B mode is set.
 *
 * @param route
 */
export function isBusinessMode(route: Route): boolean {
    if (SelectorQueryParam.B2B_FILTER in route.query) {
        return route.query[SelectorQueryParam.B2B_FILTER] === '1';
    }

    return false;
}

/**
 * Check if showing deals is set.
 *
 * @param route
 */
export function getBestDeal(route: Route): boolean {
    if (SelectorQueryParam.BEST_DEAL in route.query) {
        return route.query[SelectorQueryParam.BEST_DEAL] === '1';
    }

    return false;
}

/**
 * Get the current country code.
 *
 * @param route
 * @return the current country or undefined
 */
export function getCC(route: Route): Country | undefined {
    if (route.query.cc) {
        if (route.query.cc === 'at') {
            return Country.AT;
        }

        if (route.query.cc === 'de') {
            return Country.DE;
        }

        if (route.query.cc === 'ch') {
            return Country.CH;
        }

        if (route.query.cc === 'fr') {
            return Country.FR;
        }
    }

    return undefined;
}

/**
 * Get the current car filter param.
 *
 * @param route
 * @return the current car filter (ids) or undefined
 */
export function getCarFilterIds(route: Route): number[] | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.CAR_FILTER)) {
        // car filter is set, get the ids as numbers
        return route.query.cars
            .toString()
            .split(',')
            .map((carId) => Number(carId));
    }

    return undefined;
}

/**
 * Get the current make/brand filter param.
 *
 * @param route
 * @return the current brand/make filter (ids as Brand enum) or undefined
 */
export function getBrandFilterIds(route: Route): Brand[] | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.MAKE_FILTER)) {
        // brand/make filter is set
        return route.query.make
            .toString()
            .split(',')
            .map((makeId) => Number(makeId));
    }

    return undefined;
}

/**
 * Get the current vehicle class filter param.
 *
 * @param route
 * @return the current vehicle class or undefined
 */
export function getVehicleClass(route: Route): VehicleClass[] | undefined {
    if (
        Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.VEHICLE_CLASS_FILTER)
    ) {
        return route.query.class
            .toString()
            .split(',')
            .map((c) => Number(c));
    }

    return undefined;
}

/**
 * Get the current vehicle type filter param.
 *
 * @param route
 * @return the current vehicle type or undefined
 */
export function getVehicleType(route: Route): VehicleType[] | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.VEHICLE_TYPE_FILTER)) {
        return route.query.type
            .toString()
            .split(',')
            .map((t) => Number(t));
    }

    return undefined;
}

/**
 * Get the current seat filter param.
 *
 * @param route
 * @return the current seats or undefined
 */
export function getSeats(route: Route): number[] | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.SEAT_FILTER)) {
        // seat filter is set, get the numbers
        return route.query.seats
            .toString()
            .split(',')
            .map((seat) => Number(seat));
    }

    return undefined;
}

/**
 * Get the current range filter param.
 *
 * @param route
 * @return the current range or undefined
 */
export function getRange(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.RANGE_FILTER)) {
        return Number(route.query.range);
    }

    return undefined;
}

/**
 * Get the current charge time filter param.
 *
 * @param route
 * @return the current charge time or undefined
 */
export function getChargeTime(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.CHARGE_TIME_FILTER)) {
        return Number(route.query.chargetime);
    }

    return undefined;
}

/**
 * Get the current charge rate filter param.
 *
 * @param route
 * @return the current charge rate or undefined
 */
export function getChargeRate(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.CHARGE_RATE_FILTER)) {
        return Number(route.query.chargerate);
    }

    return undefined;
}

/**
 * Get the current acceleration filter param.
 *
 * @param route
 * @return the current acceleration or undefined
 */
export function getAcceleration(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.ACCELERATION_FILTER)) {
        return Number(route.query.acceleration);
    }

    return undefined;
}

/**
 * Get the current drive filter param.
 *
 * @param route
 * @return the current drive or undefined
 */
export function getDrive(route: Route): Drive[] | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.DRIVE_FILTER)) {
        return route.query.drive
            .toString()
            .split(',')
            .map((d) => Number(d));
    }

    return undefined;
}

/**
 * Get the current hitch filter param.
 *
 * @param route
 * @return the current hitch or undefined
 */
export function getHitch(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.HITCH_FILTER)) {
        return Number(route.query.hitch);
    }

    return undefined;
}

/**
 * Get the current availability filter param.
 *
 * @param route
 * @return the current availability or undefined
 */
export function getAvailability(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.AVAILABILITY_FILTER)) {
        return Number(route.query.availability);
    }

    return undefined;
}

/**
 * Get the current used filter param.
 *
 * @param route
 * @return the current new filter or undefined
 */
export function getNew(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.NEW_FILTER)) {
        return Number(route.query.new);
    }

    return undefined;
}

/**
 * Get the current used filter param.
 *
 * @param route
 * @return the current used filter or undefined
 */
export function getUsed(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.USED_FILTER)) {
        return Number(route.query.used);
    }

    return undefined;
}

/**
 * Get the current used young filter param.
 *
 * @param route
 * @return the current used young filter or undefined
 */
export function getUsedYoung(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.USED_YOUNG_FILTER)) {
        return Number(route.query.young);
    }

    return undefined;
}

/**
 * Get the current trial filter param.
 *
 * @param route
 * @return the current trial subscription filter or undefined
 */
export function getTrialSubscription(route: Route): number | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.TRIAL_SUBSCRIPTION)) {
        return Number(route.query.trial);
    }

    return undefined;
}

/**
 * Get the current range filter param.
 *
 * @param route
 * @return the current range or undefined
 */
export function getPrice(route: Route): number | IRangeItem | undefined {
    if (Object.prototype.hasOwnProperty.call(route.query, SelectorQueryParam.PRICE)) {
        if (typeof route.query.price === 'string') {
            let priceArray;
            if (route.query.price.includes(',')) {
                priceArray = route.query.price.split(',');
                return { from: Number(priceArray[0]), to: Number(priceArray[1]) };
            }
        }

        return Number(route.query.price);
    }

    return undefined;
}
